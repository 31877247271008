


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Contact, WriteContact } from '../../types';
import { ApiResponse } from '@/components/types';
import { CHANGE_CONTACT, DELETE_CONTACT } from '../../store';
import ContactTypeSelect from '../ContactTypeSelect.vue';
import { MapContactToWriteContact } from '../../model';

const Contact = namespace('contact');

@Component({
  components: {
    ContactTypeSelect
  }
})
export default class ChangeContactDialog extends Vue {
  @Prop({ type: Object }) value!: Contact | null;
  @Prop({ type: Boolean, default: false }) dialog!: boolean;
  @Contact.Action(CHANGE_CONTACT) changeContact!: (params: WriteContact) => Promise<ApiResponse>;
  @Contact.Action(DELETE_CONTACT) deleteContact!: (params: WriteContact) => Promise<ApiResponse>;

  error: any = null;
  contact: WriteContact | null = null;

  get iDialog() {
    return this.dialog;
  }

  set iDialog(value: boolean) {
    this.$emit('update:dialog', value);
  }

  @Watch('value', { immediate: true })
  watchContact(value: Contact | null) {
    if (!value) return;

    this.contact = MapContactToWriteContact(value);
  }

  async save() {
    if (!this.contact) return {};

    this.error = null;

    return this.changeContact(this.contact);
  }

  async remove() {
    if (!this.contact) return {};

    this.error = null;

    return this.deleteContact(this.contact);
  }
}
